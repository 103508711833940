
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { StateManager } from '@/core/statemanager/StateManager';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { FindAllStore } from '@/wms/application/layout/store/search/FindAllStore';
import { References } from '@/wms/domain/catalogs/references/References';
import { FindByReferencePackaging } from '@/wms/application/catalogs/packaging/search/FindByReferencePackaging';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import { CreateOrder } from '@/courier/application/uses_cases/order/create/CreateOrder';
import { WarehouseFindByStatus } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByStatus';
import { FindAllStorebyWarehouse } from '@/wms/application/layout/store/search/FindAllStorebyWarehouse';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { Store } from '@/wms/domain/layout/store/Store';
import { ValidationObserver } from 'vee-validate';
import { CreateDocument } from '@/courier/application/uses_cases/documents/create/CreateDocument';
import { DocumentOrderType } from '@/courier/domain/order/DocumentOrderEnum';
import { UpdateOrder } from '@/courier/application/uses_cases/order/update/UpdateOrder';
import { FindPackagingByType } from '@/wms/application/packingUnit/search/FindPackagingByType';
import { TypeDocument } from '@/settings/domain/options/TypeDocument';
import { FindReferenceStock } from '@/wms/application/stock/search/FindReferenceStock';
import { TermsPaymentFindAll } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/search/TermsPaymentFindAll';
import { EntityFindById } from '@/settings/application/uses_cases/entity/search/EntityFindById';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { CalculateAverageCost } from '@/wms/application/stock/search/CalculateAverageCost';

@Component({
  name: 'DocTransactional',
  components: {
    ListDocTransactional: () => import('@/wms/infrastructure/ui/docs/ListDocTransactional.vue'),
    CustomTableN
  }
})
export default class DocTransactional extends Vue {
  @Inject(TYPES.CREATE_ORDER)
  readonly createOrder!: CreateOrder;
  @Inject(TYPES.FINDALL_REFERENCE)
  readonly referenceFindAll!: ReferenceFindAll;
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.FIND_ALL_STORE)
  readonly findAllStore!: FindAllStore;
  @Inject(TYPES.FINDBYREFERENCE_PACKAGING_REFERENCE)
  readonly findByReferencePackaging!: FindByReferencePackaging;
  @Inject(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
  readonly consecutiveFindByWarehouseAndType!: ConsecutiveFindByWarehouseAndType;
  @Inject(TYPES.FINDBYSTATUS_WAREHOUSE)
  readonly findWarehouseByState!: WarehouseFindByStatus;
  @Inject(TYPES.FIND_ALL_STORE_WAREHOUSE)
  readonly findStoreWareHouse!: FindAllStorebyWarehouse;
  @Inject(TYPES.DOCUMENT_TYPE_SAVE)
  readonly createDocument!: CreateDocument;
  @Inject(TYPES.UPDATE_ORDER)
  readonly updateOrderDB!: UpdateOrder;
  @Inject(TYPES.FINDALL_BY_TYPE_PACKINGUNIT_REFERENCE)
  readonly findPackingByType!: FindPackagingByType;
  @Inject(TYPES.FIND_STOCK_REFERENCE)
  readonly findReferenceStock!: FindReferenceStock;
  @Inject(TYPES.FINDALL_TERMS_PAYMENT)
  readonly findAllPaymentTerms!: TermsPaymentFindAll;
  @Inject(TYPES.API_ENTITY_FIND_BY_ID)
  readonly findEntityByCode!: EntityFindById;
  @Inject(TYPES.CALCULATE_AVERAGE_COST)
  readonly calculateAverageCost!: CalculateAverageCost;

  //SELECTS
  selectedCustomer: Entity | null = null;
  selectedWarehouse: Warehouse | null = null;
  selectedCondition: any | null = null;
  //DATA
  indexLine = 1;
  orderItems: OrderLine = new OrderLine();
  isLoading = false;
  stockRestriction = 0;
  storeDestination: Store | null = null;
  payloadOriginTransfer: any = {};
  payloadDestinationTransfer: any = {};
  //LISTS
  entities = [];
  packaging = [];
  switchsA = [3, 4, 11, 13, 14, 16];
  switchsB = [1, 2, 12];
  //TABS
  tabIndex = 2;
  //ITEMS
  //PROPS
  @Prop() readonly optionSelected!: TypeDocument;
  @Prop() readonly observer!: Function;
  @Prop() readonly formOrder!: any;
  @Prop() readonly isNewDocument!: boolean;
  @Prop() readonly isEdit!: boolean;
  @Prop() readonly forView!: boolean;
  @Prop() readonly fetchTable!: Function;
  @Prop() readonly switchSpecial!: any;

  //OTHER DATA
  conditions = [
    { item: 'cash', name: this.$i18n.t('general.cash') },
    { item: 'credit', name: this.$i18n.t('general.credit') }
  ];
  typesInvoicing = [
    { id: 1, description: 'Comercial' },
    { id: 2, description: 'Regalo' }
  ];
  //TABLE ACTIONS
  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.editLineItem
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.removeItemFromLines
      }
    ]
  };
  $refs!: {
    validationForm: InstanceType<typeof ValidationObserver>;
  };
  //GETTERS
  get fields() {
    return [
      {
        field: 'reference.code',
        label: this.$i18n.t('general.SKU'),
        sortable: true,
        tdClass: (row: any) => !row.active && 'bg-danger text-white'
      },
      {
        field: 'reference.filterBrand',
        label: this.$i18n.t('general.description'),
        sortable: true
      },
      {
        field: 'quantity',
        label: this.$i18n.t('general.quantity'),
        sortable: true
      },
      {
        field: 'packaging.unit.unit',
        label: this.$i18n.t('general.packaging'),
        sortable: true
      },
      {
        field: 'packaging.numberUnits',
        label: this.$i18n.t('general.unitsquantity'),
        sortable: true
      },
      {
        field: (row: OrderLine) => {
          return !row.reference?.noManageStock ? row.unitPrice : row.servicePrice;
        },
        label: this.$i18n.t('general.originalPrice'),
        sortable: true,
        formatFn: (value: number) => (value !== null ? Number(value).toFixed(2) : 0)
      },
      {
        field: 'discountRate',
        label: this.$i18n.t('general.aditionalDiscount'),
        sortable: true
      },
      {
        field: 'totalTaxes',
        label: `${this.$i18n.t('general.taxes')} (%)`,
        sortable: true
      },
      {
        field: (row: OrderLine) => {
          return Number(
            (
              row.purchaseValue /
              (this.switchSpecial?.sw !== 13 && this.switchSpecial?.sw !== 14
                ? row.unitsQuantity
                : row.packaging?.numberUnits ?? 0)
            ).toFixed(4)
          );
        },
        label: this.$i18n.t('general.unitprice'),
        sortable: true
      },
      {
        field: 'purchaseValue',
        label: this.$i18n.t('general.price'),
        sortable: true
      },
      {
        field: 'actions',
        label: this.$i18n.t('general.actions'),
        hidden: this.forView
      }
    ];
  }

  get formatPrice() {
    return this.orderItems.unitPrice;
  }

  get orderTypeList() {
    return [DocumentOrderType.purchaseOrder, DocumentOrderType.clientOrder];
  }

  get conditionPayment() {
    return `${this.formOrder.condition}`;
  }

  get customerAddress() {
    return '';
  }

  get customerPhone() {
    let phone = '';
    if (this.selectedCustomer != null && this.selectedCustomer?.contacts.length > 0) {
      this.selectedCustomer?.contacts.map(item => {
        if (item.mainContact) {
          phone = item.phone;
        }
      });
    }
    return phone;
  }

  get customerEmail() {
    let email = '';
    if (this.selectedCustomer != null && this.selectedCustomer?.contacts.length > 0) {
      this.selectedCustomer?.contacts.map(item => {
        if (item.mainContact) {
          email = item.email;
        }
      });
    }
    return email;
  }

  get calculatedValue() {
    if (this.orderItems.unitPrice == null) return '0';
    return (
      this.orderItems.unitPrice *
      (1 - this.orderItems.discountRate / 100) *
      (this.orderItems.packaging?.numberUnits ?? 0)
    ).toFixed(2);
  }

  get calculatedTotalValue() {
    if (this.calculatedValue == null) return 0;
    return (
      parseFloat(this.calculatedValue) *
      (1 + this.orderItems.totalTaxes / 100) *
      (this.switchSpecial?.sw !== 13 && this.switchSpecial?.sw !== 14
        ? this.orderItems.quantity
        : this.orderItems.packaging?.numberUnits ?? 0)
    ).toFixed(2);
  }

  get totalOrderValue() {
    let sum = 0;
    this.formOrder.lines.forEach((line: any) => {
      sum += line.active ? line.purchaseValue : 0;
    });

    return sum.toFixed(2);
  }

  get totalTaxesValue() {
    let sum = 0;
    this.formOrder.lines.forEach((line: any) => {
      sum += line.active ? (line.partialPrice * line.totalTaxes) / 100 : 0;
    });

    return sum > 0 ? sum.toFixed(2) : 0;
  }

  get subTotal() {
    let sum = 0;
    this.formOrder.lines.forEach((line: any) => {
      sum += line.active ? line.partialPrice : 0;
    });

    return sum.toFixed(2);
  }

  get servicesTotal() {
    return 0;
  }

  get dateNow() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const today = String(new Date().getDate()).padStart(2, '0');
    return `${year}-${month < 10 ? '0' : ''}${month}-${today}`;
  }

  get actualCapacity() {
    let sum = 0;
    this.formOrder.lines.forEach((line: any) => {
      sum += line.unitsQuantity;
    });

    return sum;
  }

  mounted() {
    this.referenceFindAll.execute();
    this.searchWarehouse();
    this.getTermsPayment();
    !this.isEdit ? this.getConsecutive() : this.findByCode(this.formOrder.customerCode);
    this.formOrder.date = this.dateNow;
    if (this.switchSpecial?.sw === 14 || this.switchSpecial?.sw === 13) this.orderItems.quantity = 0;
  }

  addNewItemtoForm() {
    if (
      (this.orderItems.packaging?.numberUnits ?? 0) * this.orderItems.quantity + this.actualCapacity >
        this.formOrder.maxCapacity &&
      this.isNewDocument
    ) {
      this.$swal({
        title: `${this.$t('general.warning')}`,
        text: `${this.$t('general.excedMaxCapacity')}`,
        icon: 'warning'
      });
      return;
    }

    if (
      this.optionSelected.acr == 'INC' &&
      !this.orderItems.reference?.noManageStock &&
      this.calculateToMinUnit > this.stockRestriction
    ) {
      this.$swal({
        title: `${this.$t('general.warning')}`,
        text: `${this.$t('general.excedStock')}`,
        icon: 'warning'
      });
      return;
    }

    this.orderItems.originalPrice = parseFloat(this.calculatedTotalValue.toString());
    this.orderItems.purchaseValue = parseFloat(this.calculatedTotalValue.toString());
    this.orderItems.partialPrice = Number((parseFloat(this.calculatedValue) * this.orderItems.quantity).toFixed(2));
    this.orderItems.unitsQuantity = this.calculateToMinUnit;
    this.orderItems.handleInventory = this.isOrder() ? 'N' : 'S';

    if (this.switchsA.includes(this.optionSelected.sw)) {
      this.orderItems.unitCost = Number(this.calculatedValue);
      this.orderItems.unitValue = this.orderItems.unitCost;
    } else if (this.switchsB.includes(this.optionSelected.sw)) {
      this.orderItems.unitValue = Number(this.calculatedValue);

      if (this.switchSpecial?.sw === 13 || this.switchSpecial?.sw === 14) {
        this.orderItems.unitCost = this.orderItems.unitValue;
      }
    }

    this.formOrder.lines.push(this.orderItems);

    //Calculo de la distribucion de precio
    if (this.optionSelected.acr == 'WR') this.calculatePriceDistribution();

    this.clearFormOrderLine();
  }

  async removeItemFromLines(item: any) {
    //Se muestra un mensaje de confirmacion
    const message = await this.$swal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      title: this.$t('general.info'),
      text: `${this.$t('general.areyousuretodeletethis')} ${this.$t('general.reference')} (${
        item.reference.filterBrand
      })`,
      confirmButtonText: `${this.$t('general.delete')}`,
      cancelButtonText: `${this.$t('general.cancel')}`,
      icon: 'warning'
    });

    if (!message.isConfirmed) return;
    const itemPos = this.formOrder.lines.indexOf(item);
    //Si se confirma se ejecuta la actualización del estado del timeline

    if (item.number) {
      item.active = false;
      return;
    }

    this.formOrder.lines.splice(itemPos, 1);
    if (this.optionSelected.acr == 'WR') this.calculatePriceDistribution();
  }

  editLineItem(item: any, index: number) {
    this.formOrder.lines.splice(index, 1);
    this.orderItems = item;
  }

  calculatePriceDistribution() {
    let totalServices = 0;
    let totalLines = 0;
    this.formOrder.lines.forEach((line: OrderLine) => {
      if (line.reference?.noManageStock) {
        totalServices += Number(line.servicePrice);
      } else {
        totalLines += Number(line.originalPrice);
      }
    });

    const percentage = totalLines > 0 ? totalServices / totalLines : 0;
    this.formOrder.lines.forEach((line: OrderLine) => {
      if (!line.reference?.noManageStock) {
        line.purchaseValue = Number(
          Number(line.originalPrice * ((line.reference?.isPromotional ? 0 : 1) + percentage)).toFixed(2)
        );
      }
    });
  }

  clearFormOrderLine() {
    this.orderItems = new OrderLine();
  }
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  asyncFind(query: string) {
    if (query.length >= 3) {
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER']
      };
      this.isLoading = true;
      this.customerFindFilter
        .execute(params)
        .then((response: any) => {
          this.entities = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }

  async findByCode(code: number) {
    try {
      this.isLoading = true;
      const res = await this.findEntityByCode.execute(code);
      this.selectedCustomer = res as any;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  findPackaginByReference(refrence: References) {
    let value = 0;
    switch (this.optionSelected.acr) {
      case 'PO':
        value = refrence.standardCost;
        break;
      case 'WR':
        value = refrence.standardCost;
        break;
      case 'RTS':
        value = refrence.standardCost;
        break;
      default:
        value = refrence.msrp;
        break;
    }

    if (refrence.noManageStock) {
      this.orderItems.servicePrice = value || 0;
      this.orderItems.quantity = this.switchSpecial?.sw !== 13 && this.switchSpecial?.sw !== 14 ? 1 : 0;
    } else {
      this.orderItems.unitPrice = value || 0;
    }

    if (refrence.applyDiscount) {
      this.orderItems.discountRate = Number(this.selectedCustomer?.discountPercentage) || 0;
    }

    if (this.formOrder.store || this.formOrder.storeId) {
      this.getStockRestriction(refrence, this.formOrder.store ? this.formOrder.store.storeId : this.formOrder.storeId);
      this.$root.$emit('bv::hide::popover', 'stockAvailable');
    } else {
      this.$root.$emit('bv::show::popover', 'stockAvailable');
    }

    if (this.switchsB.includes(this.optionSelected.sw)) this.getReferenceAverageCost(refrence);

    const taxes = this.optionSelected.acr == 'PO' ? refrence.purchaseTax : refrence.saleTax;
    this.orderItems.totalTaxes = taxes > 0 ? taxes : 0;
    this.findPackingByType.execute(this.optionSelected.acr == 'PO' ? 'purchase' : 'sale').then((response: any) => {
      this.packaging = response.filter((item: any) => item.codeRef == refrence.code);
      this.orderItems.packaging = new Packaging();
    });
  }
  codeDescriptionReference(reference: References) {
    return `${reference.code} - ${reference.filterBrand}`;
  }
  nameUnit(packaging: Packaging) {
    return `${packaging.unit?.description}`;
  }
  getConsecutive() {
    const pyload = {
      type: this.optionSelected.acr,
      warehouse: '000'
    } as const;
    this.consecutiveFindByWarehouseAndType
      .execute(pyload)
      .then((response: any) => {
        this.formOrder.number = Number(response.nextNumber) + 1;
      })
      .catch((err: any) => {
        return err;
      });
  }
  async saveForm() {
    if (!(await this.$refs.validationForm.validate())) return;
    if (this.selectedCustomer == null && !this.isNewDocument && !this.isEdit) return;
    if (this.formOrder.lines.length < 1) {
      this.$swal({
        title: `${this.$t('general.warning')}`,
        text: `${this.$t('general.errorPackagesNumber')}`,
        icon: 'warning'
      });
      return;
    }
    this.formOrder.customerCode =
      !this.isNewDocument && !this.isEdit ? this.selectedCustomer?.code : this.formOrder.customerCode;
    this.formOrder.customerId =
      !this.isNewDocument && !this.isEdit ? this.selectedCustomer?.id : this.formOrder.customerId;

    this.formOrder.module = 'wms_commercial';
    this.formOrder.typeOrder = this.optionSelected.acr;

    const payload: any = {
      ...this.formOrder,
      sw: this.optionSelected.acr != 'SN' ? this.optionSelected.sw : this.switchSpecial.sw,
      originAddress: null,
      destination: null,
      condition: !this.isEdit && !this.isNewDocument ? this.selectedCondition?.name : this.formOrder.condition,
      status: [],
      lines: this.formOrder.lines.map((item: any) => {
        return {
          ...item,
          refrence: null,
          handleInventory: this.optionSelected.acr == 'CO' || this.optionSelected.acr == 'CPO' ? 'N' : 'S'
        };
      }),
      total: this.totalOrderValue,
      storeId: !this.isNewDocument && !this.isEdit ? this.formOrder.store.storeId : this.formOrder.storeId,
      storeName: !this.isNewDocument && !this.isEdit ? this.formOrder.store.storeName : this.formOrder.storeName,
      bondedEntryNumber: this.formOrder.forBondedEntry ? this.formOrder.bondedEntryNumber : 0,
      documentType: {
        sw: this.optionSelected.sw,
        type: this.optionSelected.acr
      }
    };

    delete payload['palletFactor'];
    delete payload['rates'];
    delete payload['palletFactorValue'];
    delete payload['palletFactorValue'];

    if (this.optionSelected.sw === 12 && this.switchSpecial?.sw === 16) {
      this.payloadOriginTransfer = {
        ...payload,
        sw: 11,
        storeId: !this.isNewDocument && !this.isEdit ? this.formOrder.store.storeId : this.formOrder.storeId,
        storeName: !this.isNewDocument && !this.isEdit ? this.formOrder.store.storeName : this.formOrder.storeName
      };

      this.payloadDestinationTransfer = {
        ...payload,
        number: this.payloadOriginTransfer.number + 1,
        store: this.storeDestination,
        storeId: !this.isNewDocument && !this.isEdit ? this.storeDestination?.storeId : this.formOrder.storeId,
        storeName: !this.isNewDocument && !this.isEdit ? this.storeDestination?.storeName : this.formOrder.storeName
      };
    }

    if (!this.isEdit) {
      try {
        if (this.optionSelected.sw !== 12 && this.switchSpecial?.sw !== 16) {
          this.isOrder() ? await this.saveOrder(payload) : await this.saveDocument(payload);
        } else {
          if (this.payloadOriginTransfer.store == this.payloadDestinationTransfer.store) {
            this.$swal({
              title: `${this.$t('general.warning')}`,
              text: `${this.$t('general.errorStoreEquals')}`,
              icon: 'warning'
            });

            return;
          }

          await this.saveDocument(this.payloadOriginTransfer);
          await this.saveDocument(this.payloadDestinationTransfer);
        }
      } catch (error) {
        throw new Error(`${error}`);
      }
    } else {
      try {
        this.isOrder() ? await this.updateOrder(payload) : null;
      } catch (error) {
        throw new Error(`${error}`);
      }
    }
    this.observer(this.formOrder);
  }
  cancelForm() {
    this.observer(null);
  }
  get numberIndexLine() {
    return this.formOrder.lines.length;
  }
  get calculateToMinUnit() {
    if (this.orderItems.quantity > 0 && this.orderItems.packaging && this.orderItems.packaging?.numberUnits > 0) {
      return Number(this.orderItems.quantity) * Number(this.orderItems.packaging.numberUnits);
    }
    return 0;
  }
  //stage manager
  get listReferences() {
    return this.stateManager.state.dataReferences;
  }
  get listStore() {
    return this.stateManager.state.dataStore;
  }
  get listStoreFiltered() {
    return this.stateManager.state.dataStore.filter((store: Store) => store !== this.formOrder.store);
  }
  get listWarehouse() {
    return this.stateManager.state.dataWarehouse;
  }

  searchWarehouse() {
    this.isLoading = true;
    this.findWarehouseByState
      .execute(true)
      .then((response: any) => {
        this.isLoading = false;
        this.stateManager.state.dataWarehouse = response;
      })
      .catch((err: any) => {
        this.isLoading = false;

        return err;
      });
  }

  searchStorage(input: Warehouse) {
    if (this.selectedWarehouse?.code === '') return;
    this.isLoading = true;
    this.findStoreWareHouse
      .execute(input.code)
      .then((response: any) => {
        this.isLoading = false;
        this.stateManager.state.dataStore = response;
      })
      .catch((err: any) => {
        this.isLoading = false;
        return err;
      });
  }

  handdleChangeCustomerData(input: Entity) {
    this.formOrder.originAddress = input.address;
    this.formOrder.customerCode = input.code;
    this.formOrder.customerId = input.id;
  }

  handdleChangeWarehouseDate(input: Warehouse) {
    this.formOrder.warehouse = input.code;
    this.formOrder.warehouseName = input.description;
    this.formOrder.store = null;
  }

  async saveOrder(payload: any) {
    this.isLoading = true;
    await this.createOrder
      .execute(payload)
      .then(() => {
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
        this.fetchTable();
      });
  }

  async saveDocument(payload: any) {
    this.isLoading = true;
    await this.createDocument
      .execute(payload)
      .then(() => {
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
        this.fetchTable();
      });
  }

  async updateOrder(payload: any) {
    this.isLoading = true;
    await this.updateOrderDB
      .execute(payload)
      .then(() => {
        this.isLoading = false;
        this.fetchTable();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  verifyCustomer(input: Entity) {
    if (input.contacts.length < 1) {
      this.$swal({
        title: this.$t('general.warning'),
        text: `${this.$t('general.undefinedPhoneOrEmail')}`,
        icon: 'warning'
      });
      this.selectedCustomer = null;
      return false;
    }
    const date = new Date();
    date.setDate(date.getDate() + Number(input.delivery));
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const today = String(date.getDate()).padStart(2, '0');
    this.formOrder.dueDate = String(`${year}-${month < 10 ? '0' : ''}${month}-${today}`);
    this.selectedCondition = input.termsPayment;
  }

  setForBondedEntry(input: Store) {
    this.formOrder.forBondedEntry = input.forBondedEntry ? true : false;
    if (this.orderItems.reference) {
      this.getStockRestriction(this.orderItems.reference, input.storeId);
      this.$root.$emit('bv::hide::popover', 'stockAvailable');
    }
  }

  isOrder(): boolean {
    return this.optionSelected.acr == 'PO' || this.optionSelected.acr == 'CPO';
  }

  async getStockRestriction(reference: References, storeId: number) {
    this.isLoading = true;
    const res = await this.findReferenceStock.execute({
      reference: encodeURIComponent(reference.code),
      iMonth: new Date(this.formOrder.date).getMonth() + 1,
      store: storeId
    });
    this.stockRestriction = res.length > 0 ? res.at(-1).stock : 0;
    this.isLoading = false;
  }

  async getReferenceAverageCost(reference: References) {
    try {
      this.isLoading = true;
      const res = await this.calculateAverageCost.execute({
        reference: encodeURIComponent(reference.code)
      });

      if (this.optionSelected.sw === 12) this.orderItems.unitPrice = Number(res.costoPromedio.toFixed(2));
      this.orderItems.unitCost = Number(res.costoPromedio.toFixed(2));
      this.orderItems.unitValue = !reference.noManageStock
        ? Number(this.orderItems.unitPrice)
        : Number(this.orderItems.servicePrice);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getTermsPayment() {
    const res = await this.findAllPaymentTerms.execute();
    this.conditions = res as any[];
  }

  calculateLinePrice() {
    let value = !['WR', 'PO', 'RTS'].includes(this.optionSelected.acr)
      ? this.orderItems.reference?.msrp
      : this.orderItems.reference?.standardCost;

    if (this.optionSelected.acr == 'WR' && this.orderItems.reference?.noManageStock) {
      this.orderItems.servicePrice = Number(value);
    }
    if (value == null) value = 0;
    this.orderItems.unitPrice = value;
  }
}
