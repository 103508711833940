export class TypeDocument {
  name: string;
  acr: string;
  sw: number;
  buttonRender: boolean;
  docTitle: string;

  public constructor(name: string, acr: string, sw: number, buttonRender: boolean, docTitle: string) {
    this.name = name;
    this.acr = acr;
    this.sw = sw;
    this.buttonRender = buttonRender;
    this.docTitle = docTitle;
  }
}
