
import Vue from 'vue';
import Component from 'vue-class-component';
import DocTransactional from '@/wms/infrastructure/ui/docs/DocTransactional.vue';
import { Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ListTransactional from '@/wms/infrastructure/ui/docs/ListTransactional.vue';
import ListDocumentComponent from './ListComponent/ListDocumentComponent.vue';
import { CourierOrder } from '@/courier/domain/CourierOrder/CourierOrder';
import { Order } from '@/courier/domain/order/Order';
import { TYPES } from '@/core/config/Types';
import { OrderFindByPk } from '@/courier/application/uses_cases/order/search/OrderFindByPk';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { AddressLine } from '@/settings/domain/address/AddressLine';
import { DocumentFindByPk } from '@/courier/application/uses_cases/documents/search/DocumentFindByPk';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { TermsPaymentFindAll } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/search/TermsPaymentFindAll';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';

@Component({
  name: 'ListDocTransactional',
  components: { DocTransactional, ListTransactional, ListDocumentComponent }
})
export default class ListDocTransactional extends Vue {
  @Inject(TYPES.FINDBYPK_ORDER) readonly findOrderByPk!: OrderFindByPk;
  @Inject(TYPES.DOCUMENT_TYPE_FIND_BY_PK) readonly findDocumentByPk!: DocumentFindByPk;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE) readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.FINDBYID_COMPANY) readonly companyFindById!: CompanyFindById;

  @Inject(TYPES.FINDALL_TERMS_PAYMENT)
  readonly findAllPaymentTerms!: TermsPaymentFindAll;

  //DATA
  formOrder: CourierOrder = new CourierOrder();
  isNewDocument = false;
  isEdit = false;
  forView = false;
  isLoading = false;

  @Prop() readonly optionSelected!: any;
  @Prop() readonly items!: any[];
  @Prop() readonly onlyTable!: boolean;
  @Prop() readonly observer!: Function;
  @Prop() readonly fields!: any;

  switchEspecial = { sw: 12, label: `${this.$t('general.entries')}` };
  //GETTERS
  get switchOptions() {
    return [
      { sw: 12, label: `${this.$t('general.entries')}` },
      { sw: 11, label: `${this.$t('general.exits')}` },
      { sw: 16, label: `${this.$t('general.transfers')}` },
      { sw: 14, label: `${this.$t('general.costAdjustments')} (${this.$t('general.increase')})` },
      { sw: 13, label: `${this.$t('general.costAdjustments')} (${this.$t('general.decrease')})` }
    ];
  }

  get fieldsAction() {
    return [
      { field: 'number', label: `#` },
      {
        field: `${this.optionSelected.acr != 'WR' && this.optionSelected.acr != 'INC' ? 'type' : 'typeOrder'}`,
        label: this.translateCol('type')
      },
      { field: 'concept', label: this.translateCol('concept') },
      { field: 'date', label: this.translateCol('date') },
      { field: 'customerName', label: this.translateCol('entity') },
      { field: 'warehouseName', label: this.translateCol('warehouse') },
      { field: 'total', label: this.translateCol('total') },
      { field: 'actions', label: this.translateCol('actions') }
    ];
  }

  $refs!: {
    bvModal: HTMLFormElement;
    listDocsModal: HTMLFormElement;
    tableComponent: ListDocumentComponent;
    documentList: HTMLFormElement;
  };

  controllerModal(object: any) {
    if (object) this.observer(object);

    this.$bvModal.hide('DocTransactional-modal');
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }

  actions = {
    customActions: [
      {
        title: `${this.$t('general.load')}`,
        icon: 'fa fa-file',
        variant: 'secondary',
        action: this.load
      }
    ]
  };

  actionsMainTable = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.editOnMainTable,
        conditionalRender: () => {
          return !this.isOrder();
        }
      },
      {
        title: `${this.$t('general.details')}`,
        icon: 'fa fa-eye',
        variant: 'warning',
        action: this.showDetails
      },
      {
        title: `${this.$t('general.download')}`,
        icon: 'fa fa-download',
        variant: 'info',
        action: this.generatePDF
      }
    ]
  };

  async load(item: Order) {
    this.forView = false;
    this.isLoading = true;
    this.optionSelected.acr != 'CR' && this.optionSelected.acr != 'RTS'
      ? await this.findOrder(item)
      : await this.findDocument(item);
    this.$refs.documentList.hide();
    this.$refs.bvModal.show();
    this.isLoading = false;
  }

  async findEntity(item: Order) {
    let res = null;
    const params: any = {
      text: item.customerName,
      type: ['SUPPLIER', 'CUSTOMER']
    };
    const entities = await this.customerFindFilter.execute(params);
    entities.forEach(entity => {
      if (entity.id == item.customerId) {
        res = entity;
      }
    });

    return res;
  }

  createBlankOrder() {
    this.isNewDocument = false;
    this.isEdit = false;
    this.forView = false;
    this.formOrder = new CourierOrder();
  }
  fetchTable() {
    this.$refs.tableComponent.findAll();
  }

  async setMaxCapatity(res: any) {
    let sum = 0;
    await res.lines.forEach((element: any) => {
      sum += element.unitsQuantity;
    });

    return sum;
  }

  async editOnMainTable(item: Order) {
    this.isLoading = true;
    this.createBlankOrder();
    this.isNewDocument = false;
    this.isEdit = true;
    await this.findOrder(item);
    this.$refs.bvModal.show();
    this.isLoading = false;
  }

  async showDetails(item: Order) {
    this.isLoading = true;
    this.isEdit = true;
    this.isNewDocument = true;
    this.forView = true;
    this.switchEspecial = this.switchOptions.find((option: any) => option.sw == item.switchCode) as any;
    this.isOrder() ? await this.findOrder(item) : await this.findDocument(item);
    this.$refs.bvModal.show();
    this.isLoading = false;
  }

  async findOrder(item: Order) {
    const res: any = await this.findOrderByPk.execute({
      number: item.number,
      type: item.typeOrder
    });

    this.formOrder = {
      ...res,
      originAddress: new AddressLine(),
      maxCapacity: await this.setMaxCapatity(res),
      associatedDocumentNumber: res.number,
      typeDocAssociated: res.typeOrder,
      forBondedEntry: res.forBondedEntry,
      lines: res.lines.map((line: any) => ({
        ...line,
        _rowVariant: null
      }))
    };

    this.formOrder.originAddress.description = item.addressOriginLine;
  }

  async getCondition(orderTerm: string): Promise<TermsPayment> {
    try {
      this.isLoading = true;
      const res = await this.findAllPaymentTerms.execute();

      this.isLoading = false;
      return res.filter((term: TermsPayment) => term.description == orderTerm)[0];
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findDocument(item: any) {
    const res: any = await this.findDocumentByPk.execute({
      number: item.number,
      type: item.type
    });

    this.formOrder = {
      ...res,
      originAddress: new AddressLine(),
      maxCapacity: await this.setMaxCapatity(res),
      associatedDocumentNumber: res.number,
      typeDocAssociated: res.type,
      forBondedEntry: res.forBondedEntry,
      lines: res.lines.map((line: any) => ({
        ...line,
        _rowVariant: null
      }))
    };

    this.formOrder.originAddress.description = item.addressOriginLine;
  }

  isOrder(): boolean {
    return this.optionSelected.acr == 'PO' || this.optionSelected.acr == 'CPO';
  }

  async generatePDF(item: any) {
    this.isLoading = true;
    const companyId = localStorage.getItem('businessId') as string;
    const companyData: any = await this.companyFindById.execute(companyId);
    const clientData: any = await this.findEntity(item);
    const clientMainContact: any = clientData.contacts.filter((item: any) => item.mainContact)[0];

    this.isOrder() ? await this.findOrder(item) : await this.findDocument(item);
    const columns = [
      [
        'SKU',
        this.$t('general.description'),
        this.$t('general.units'),
        `${this.$t('general.unitprice')} USD`,
        `${this.$t('general.discount')}`,
        `${this.$t('general.taxes')}`,
        `${this.$t('general.total')} USD`
      ]
    ];
    const data: any[] = [];
    this.formOrder.lines.forEach((item: any) => {
      const unitPriceOpr = Number(item.purchaseValue / item.unitsQuantity).toFixed(2);
      data.push([
        item.reference?.code,
        item.reference?.filterBrand,
        item.unitsQuantity,
        unitPriceOpr,
        `${item.discountRate}%`,
        `${item.totalTaxes}%`,
        Number(item.purchaseValue).toFixed(2)
      ]);
    });

    const doc = new jsPDF();
    const image = localStorage.getItem('urlLogoCompany') || '';
    const initialTextColor = doc.getTextColor();

    autoTable(doc, {
      head: columns,
      body: data,
      foot: [['', '', '', '', '', '', `Total USD: ${this.formOrder.total.toFixed(2).toString()}`]],
      margin: { top: 95 },
      headStyles: { fillColor: '#ccd8db', textColor: initialTextColor, fontSize: 6.5, halign: 'right' },
      footStyles: { fillColor: '#ccd8db', textColor: initialTextColor, fontSize: 6.5, halign: 'right' },
      bodyStyles: { fontSize: 6.5, halign: 'right' }
    });
    doc.setTextColor('#000000');
    doc.text(`_____________________________________________________________`, 10, 285);

    for (let index = 1; index <= doc.getNumberOfPages(); index++) {
      doc.setPage(index);
      doc.addImage({ imageData: image, format: 'JPEG', x: 15, y: 15, height: 40, width: 40 });
      doc.setFontSize(6.5);
      doc.text(`${companyData.businessName}`, 120, 23);
      doc.text(`${companyData.address}, ${companyData.zipCode}`, 120, 26);
      doc.text(
        `${companyData.cityDescription}, ${companyData.stateDescription}, ${companyData.countryDescription}`,
        120,
        29
      );
      doc.text(`${companyData.email}`, 120, 32);
      doc.text(`${this.$t('general.billTo')}: `, 15, 67);
      doc.text(`${this.formOrder.customerName} `, 15, 70);
      doc.text(`${clientData.address.description} `, 15, 73);
      doc.text(
        `${clientData.address.cityName}, ${clientData.address.stateName}, ${clientData.address.countryName} `,
        15,
        76
      );
      doc.text(`${clientMainContact.email}`, 15, 79);
      doc.text(`${this.$t('general.phone')}: ${clientMainContact.phone}`, 15, 82);

      doc.setDrawColor('#ccd8db');
      doc.setFillColor('#ccd8db');
      doc.roundedRect(120, 70, 75, 20, 1, 1, 'FD');
      doc.setTextColor(initialTextColor);
      doc.text(
        `${this.optionSelected.acr == 'INC' ? this.$t('general.invoiceDate') : this.$t('general.date')}: ${
          this.formOrder.date
        }`,
        125,
        75
      );
      doc.text(
        `${this.optionSelected.acr != 'CPO' ? this.optionSelected.docTitle : this.$t('general.CPO')} ${String(
          this.$t('general.number')
        ).toLowerCase()}: ${this.formOrder.number}`,
        125,
        78
      );
      doc.text(`${this.$t('general.incoterms')}: ${clientData.incoterms.name}`, 125, 81);
      doc.text(`${this.$t('general.conditionpayment')}: ${this.formOrder.condition}`, 125, 84);
      doc.text(
        `${
          this.formOrder.dueDate != '' && this.formOrder.dueDate != null
            ? `${this.$t('general.dueDate')}: ${this.formOrder.dueDate}`
            : ''
        }`,
        125,
        87
      );
      doc.text(`${this.$t('general.paginationDOC', { index, length: doc.getNumberOfPages() })}`, 98, 290);
    }

    doc.save(`${this.optionSelected.name}${this.formOrder.number}-${this.formOrder.date}`);
    this.isLoading = false;
  }
}
