import { OrderDestination } from '@/courier/domain/orderdestination/OrderDestination';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import { OrderRate } from '@/courier/domain/orderrate/OrderRate';
import { OrderHistory } from '@/courier/domain/orderhistory/OrderHistory';
import { Store } from '@/wms/domain/layout/store/Store';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { CargoTypeEnum } from './CargoTypeEnum';
import { AddressLine } from '@/settings/domain/address/AddressLine';
import { TypeDocument } from '@/settings/domain/TypeDocument/TypeDocument';

export class CourierOrder {
  approved = false;
  canceled = false;
  checked = false;
  condition: string;
  conversionRate: number;
  currency: string | null;
  customerCode: number;
  customerId: string;
  customerVirtualLocker: string;
  date: string;
  dateTime: string;
  daysValidity: number;
  deliveryDateTime: string;
  destination: OrderDestination;
  discount: number;
  document: string;
  enabled = false;
  finalSw: string;
  height: number;
  invoiced = false;
  lengthy: number;
  lines: OrderLine[];
  notes: string;
  number: number;
  palletFactor: number;
  palletFactorName: string;
  palletFactorValue: number;
  rateMinValue: number;
  rateValue: number;
  rates: OrderRate;
  routeDescription: string;
  routeId: number;
  status: OrderHistory;
  tax: number;
  timelineId: number;
  timelineName: string;
  total: number;
  typeOrder: string;
  user: string;
  vendor: string;
  warehouse: Warehouse | string;
  warehouseName: string;
  store: Store | null;
  weight: number;
  width: number;
  concept: string;
  module: string;
  typeInvoicing: string;
  originAddress: AddressLine;
  cargoType: CargoTypeEnum;
  sw: number;
  bondedEntryNumber: number;
  typeDocAssociated: string;
  associatedDocumentNumber: string;
  customerName: string;
  dueDate: string;
  typeDocument: TypeDocument | null;

  public constructor() {
    this.customerName = '';
    this.bondedEntryNumber = 0;
    this.originAddress = new AddressLine();
    this.approved = false;
    this.canceled = false;
    this.checked = false;
    this.condition = 'cash';
    this.conversionRate = 0;
    this.currency = null;
    this.customerCode = 0;
    this.customerId = '0';
    this.customerVirtualLocker = '';
    this.date = '';
    this.dateTime = '';
    this.daysValidity = 0;
    this.deliveryDateTime = '';
    this.destination = new OrderDestination();
    this.discount = 0;
    this.document = '';
    this.enabled = false;
    this.finalSw = '';
    this.height = 0;
    this.invoiced = false;
    this.lengthy = 0;
    this.lines = [];
    this.notes = '';
    this.number = 0;
    this.palletFactor = 0;
    this.palletFactorName = '';
    this.palletFactorValue = 0;
    this.rateMinValue = 0;
    this.rateValue = 1;
    this.rates = new OrderRate();
    this.routeDescription = '';
    this.routeId = 0;
    this.status = new OrderHistory();
    this.tax = 0;
    this.timelineId = 0;
    this.timelineName = '';
    this.total = 0;
    this.typeOrder = 'CT';
    this.user = '';
    this.vendor = '';
    this.warehouse = '';
    this.warehouseName = '';
    this.store = null;
    this.weight = 0;
    this.width = 0;
    this.concept = '';
    this.sw = 1;
    this.module = '';
    this.typeInvoicing = '';
    this.cargoType = CargoTypeEnum.none;
    this.associatedDocumentNumber = '';
    this.typeDocAssociated = '';
    this.dueDate = '';
    this.typeDocument = null;
  }
}
